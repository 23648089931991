import {IViolation, IViolationSeverity} from '../../types/app.types';
import {ViolationTargetModel} from './ViolationTarget.model';

export class ViolationModel {
  public severity: ViolationSeverity;
  public target: ViolationTargetModel;
  public description: string;

  constructor(params: ViolationModel) {
    this.severity = params.severity;
    this.target = params.target;
    this.description = params.description;
  }

  public static fromGQL(violation: IViolation) {
    return new ViolationModel({
      severity: toSeverity(violation.severity),
      target: ViolationTargetModel.fromGQL(violation.target),
      description: violation.description,
    });
  }
}

const toSeverity = (severity: IViolationSeverity): ViolationSeverity => {
  const map: Record<IViolationSeverity, ViolationSeverity> = {
    ERROR: ViolationSeverity.error,
    WARNING: ViolationSeverity.warning,
  };

  return map[severity];
};

export enum ViolationSeverity {
  error = 'ERROR',
  warning = 'WARNING',
}
