import {IViolationNameInOther, IViolationOtherTarget} from '../../types/app.types';

export class ViolationOtherTargetModel {
  public name?: ViolationOtherTargetName;

  constructor(params: ViolationOtherTargetModel) {
    this.name = params.name;
  }

  public static fromGQL(otherTarget?: IViolationOtherTarget) {
    return new ViolationOtherTargetModel({
      name: otherTarget?.name ? toViolationOtherTargetName(otherTarget.name) : /* istanbul ignore next */ undefined,
    });
  }
}

export enum ViolationOtherTargetName {
  default = 'OTHER_DEFAULT',
  delivery = 'DELIVERY',
}

const toViolationOtherTargetName = (name: IViolationNameInOther): ViolationOtherTargetName => {
  const map: Record<IViolationNameInOther, ViolationOtherTargetName> = {
    OTHER_DEFAULT: ViolationOtherTargetName.default,
    DELIVERY: ViolationOtherTargetName.delivery,
  };

  return map[name];
};
