import {IViolationLineItemTarget, IViolationNameInLineItem} from '../../types/app.types';

export class ViolationLineItemTargetModel {
  public id: string;
  public name?: ViolationLineItemTargetName;

  constructor(params: ViolationLineItemTargetModel) {
    this.id = params.id;
    this.name = params.name;
  }

  public static fromGQL(otherTarget?: IViolationLineItemTarget) {
    return new ViolationLineItemTargetModel({
      id: otherTarget.id,
      name: otherTarget?.name ? toViolationOtherTargetName(otherTarget.name) : /* istanbul ignore next */ undefined,
    });
  }
}

export enum ViolationLineItemTargetName {
  default = 'LINE_ITEM_DEFAULT',
}

const toViolationOtherTargetName = (name: IViolationNameInLineItem): ViolationLineItemTargetName => {
  const map: Record<IViolationNameInLineItem, ViolationLineItemTargetName> = {
    LINE_ITEM_DEFAULT: ViolationLineItemTargetName.default,
  };

  return map[name];
};
