import {IViolationTarget} from '../../types/app.types';
import {ViolationLineItemTargetModel} from './ViolationLineItemTarget.model';
import {ViolationOtherTargetModel} from './ViolationOtherTarget.model';

export class ViolationTargetModel {
  public lineItemTarget?: ViolationLineItemTargetModel;
  public otherTarget?: ViolationOtherTargetModel;

  constructor(params: ViolationTargetModel) {
    this.lineItemTarget = params.lineItemTarget;
    this.otherTarget = params.otherTarget;
  }

  public static fromGQL(target: IViolationTarget) {
    return new ViolationTargetModel({
      lineItemTarget: target?.lineItem ? ViolationLineItemTargetModel.fromGQL(target.lineItem) : undefined,
      otherTarget: target?.other ? ViolationOtherTargetModel.fromGQL(target.other) : undefined,
    });
  }
}
