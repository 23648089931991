import {IOption} from '../../types/app.types';

export class OptionModel {
  public id: number;
  public title: string;
  public value: string;

  constructor(params: OptionModel) {
    this.id = params.id;
    this.title = params.title;
    this.value = params.value;
  }

  public static fromGQL(Option: IOption) {
    return new OptionModel({
      id: Option?.id,
      title: Option?.title,
      value: Option?.value,
    });
  }
}
