import {ShippingInfoModel} from './ShippingInfo.model';
import {ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk';
import {PriceSummaryModel} from './PriceSummary.model';
import {ViolationModel} from './Violation.model';
import {AdditionalFeeModel} from './AdditionalFee.model';
import {MinimumOrderAmountModel} from './MinimumOrderAmount.model';

export class EstimatedTotalsModel {
  public priceSummary: PriceSummaryModel;
  public shippingInfo: ShippingInfoModel;
  public payNow: PriceSummaryModel;
  public payLater: PriceSummaryModel;
  public additionalFees: AdditionalFeeModel[];
  public violations: ViolationModel[];
  public minimumOrderAmount: MinimumOrderAmountModel;
  public estimatedAmountToPay: number;

  constructor(estimatedTotals: ICartFromCartApi) {
    this.priceSummary = PriceSummaryModel.fromGQL({
      convertedTax: estimatedTotals?.convertedTotals?.tax,
      formattedConvertedTax: estimatedTotals?.convertedTotals?.formattedTax,
      convertedSubtotal: estimatedTotals?.convertedTotals?.subTotal,
      convertedTotal: estimatedTotals?.convertedTotals?.total,
      formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedTotal,
      formattedConvertedSubTotal: estimatedTotals?.convertedTotals?.formattedSubTotal,
      convertedShipping: estimatedTotals?.convertedTotals?.shipping,
      convertedDiscount: estimatedTotals?.convertedTotals?.discount,
      formattedConvertedDiscount: estimatedTotals?.convertedTotals?.formattedDiscount,
      formattedConvertedShipping: estimatedTotals?.convertedTotals?.formattedShipping,
      formattedConvertedItemsTotal: estimatedTotals?.convertedTotals?.formattedItemsTotal,
      itemsTotal: estimatedTotals?.convertedTotals?.itemsTotal,
      convertedAdditionalFees: estimatedTotals?.convertedTotals?.additionalFeesTotal,
      formattedConvertedAdditionalFees: estimatedTotals?.convertedTotals?.formattedAdditionalFeesTotal,
    });
    this.shippingInfo = ShippingInfoModel.fromGQL(estimatedTotals?.shippingRuleInfo);
    this.payNow = PriceSummaryModel.fromGQL({
      total: estimatedTotals?.totals?.payNow,
      convertedTotal: estimatedTotals?.convertedTotals?.payNow,
      formattedTotal: estimatedTotals?.totals?.formattedPayNow,
      formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedPayNow,
    });
    this.payLater = PriceSummaryModel.fromGQL({
      total: estimatedTotals?.totals?.payLater,
      convertedTotal: estimatedTotals?.convertedTotals?.payLater,
      formattedTotal: estimatedTotals?.totals?.formattedPayLater,
      formattedConvertedTotal: estimatedTotals?.convertedTotals?.formattedPayLater,
    });
    this.additionalFees = (estimatedTotals?.additionalFees ?? []).map((additionalFee) =>
      AdditionalFeeModel.fromGQL(additionalFee)
    );
    this.violations = (estimatedTotals?.violations ?? []).map((violation) => ViolationModel.fromGQL(violation));
    this.minimumOrderAmount = estimatedTotals?.minimumOrderAmount
      ? MinimumOrderAmountModel.fromGQL(estimatedTotals?.minimumOrderAmount)
      : undefined;
    this.estimatedAmountToPay = estimatedTotals?.totals?.total;
  }
}
