import {OptionModel} from './Option.model';
import {IFreeText, IOption} from '../../types/app.types';
import {FreeTextModel} from './FreeText.model';

export class CatalogReferenceModel {
  public catalogItemId: string;
  public appId: string;
  public options: OptionModel[];
  public freeText: FreeTextModel[];

  constructor(params: CatalogReferenceModel) {
    this.catalogItemId = params.catalogItemId;
    this.appId = params.appId;
    this.options = params.options;
    this.freeText = params.freeText;
  }

  public static fromGQL(catalogItemId: string, appId: string, options: IOption[], freeTexts: IFreeText[]) {
    return new CatalogReferenceModel({
      catalogItemId,
      appId,
      options: (options ?? /* istanbul ignore next */ []).map((option: IOption) => OptionModel.fromGQL(option)),
      freeText: (freeTexts ?? []).map((freeText: IFreeText) => FreeTextModel.fromGQL(freeText)),
    });
  }
}
