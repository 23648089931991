export class PriceModel {
  /**
   * @deprecated
   */
  public amount: number;
  public convertedAmount: number;
  /**
   * @deprecated
   */
  public formattedAmount: string;
  public formattedConvertedAmount: string;

  constructor(params: PriceModel) {
    this.amount = params.amount;
    this.convertedAmount = params.convertedAmount;
    this.formattedAmount = params.formattedAmount;
    this.formattedConvertedAmount = params.formattedConvertedAmount;
  }

  public static fromGQL({
    amount,
    convertedAmount,
    formattedAmount,
    formattedConvertedAmount,
  }: {
    amount?: number;
    convertedAmount?: number;
    formattedAmount?: string;
    formattedConvertedAmount?: string;
  }) {
    return new PriceModel({
      amount,
      convertedAmount,
      formattedAmount,
      formattedConvertedAmount,
    });
  }
}
