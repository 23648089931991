import {ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk';
import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ContactInfoModel} from './ContactInfo.model';
import {PriceModel} from './Price.model';

export class CartModel {
  public id: string;
  public lineItems: LineItemModel[];
  public subtotal: PriceModel;
  public buyerNote: string;
  public currencyCode: string;
  public convertedCurrencyCode: string;
  public checkoutId: string;
  public appliedCoupon: AppliedCouponModel;
  public purchaseFlowId: string;
  public selectedShippingOptionId: string;
  public contactInfo: ContactInfoModel;
  public subtotalAfterCoupon: PriceModel;

  constructor(params: CartModel) {
    this.id = params.id;
    this.lineItems = params.lineItems;
    this.subtotal = params.subtotal;
    this.buyerNote = params.buyerNote;
    this.currencyCode = params.currencyCode;
    this.convertedCurrencyCode = params.convertedCurrencyCode;
    this.checkoutId = params.checkoutId;
    this.appliedCoupon = params.appliedCoupon;
    this.purchaseFlowId = params.purchaseFlowId;
    this.selectedShippingOptionId = params.selectedShippingOptionId;
    this.contactInfo = params.contactInfo;
    this.subtotalAfterCoupon = params.subtotalAfterCoupon;
  }

  public static fromGQL(cart: ICartFromCartApi) {
    return new CartModel({
      id: cart?.cartId ?? undefined,
      lineItems: (cart?.items ?? []).map((item) => LineItemModel.fromGQL(item)),
      subtotal: PriceModel.fromGQL({
        convertedAmount: cart?.convertedTotals?.subTotal,
        formattedConvertedAmount: cart?.convertedTotals?.formattedSubTotal,
      }),
      buyerNote: cart?.buyerNote ?? undefined,
      currencyCode: cart?.currencyFormat?.code ?? undefined,
      convertedCurrencyCode: cart?.convertedCurrencyFormat?.code ?? undefined,
      checkoutId: cart?.checkoutId ?? undefined,
      appliedCoupon: cart?.appliedCoupon ? AppliedCouponModel.fromGQL(cart?.appliedCoupon) : undefined,
      purchaseFlowId: cart?.purchaseFlowId ?? undefined,
      selectedShippingOptionId: cart?.selectedShippingOption?.id ?? undefined,
      contactInfo: cart?.destination ? ContactInfoModel.fromGQL(cart?.destination) : undefined,
      subtotalAfterCoupon: PriceModel.fromGQL({
        convertedAmount: cart?.convertedTotals?.itemsTotal,
        formattedConvertedAmount: cart?.convertedTotals?.formattedItemsTotal,
      }),
    });
  }
}
