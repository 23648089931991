import {IAdditionalFee} from '../../types/app.types';
import {PriceModel} from './Price.model';

export class AdditionalFeeModel {
  public code: string;
  public name: string;
  public price: PriceModel;

  constructor(params: AdditionalFeeModel) {
    this.code = params.code;
    this.name = params.name;
    this.price = params.price;
  }

  public static fromGQL(additionalFee: IAdditionalFee) {
    return new AdditionalFeeModel({
      code: additionalFee.code,
      name: additionalFee.name,
      price: PriceModel.fromGQL({
        formattedConvertedAmount: additionalFee.convertedFormattedTotalPrice,
      }),
    });
  }
}
