import {IDestination} from '../../types/app.types';
import {AddressModel} from './Address.model';

export class ContactInfoModel {
  public address: AddressModel;

  constructor(params: ContactInfoModel) {
    this.address = params.address;
  }

  public static fromGQL(destination: IDestination) {
    return new ContactInfoModel({address: AddressModel.fromGQL(destination)});
  }
}
