import {IAppliedCoupon} from '../../types/app.types';

export class AppliedCouponModel {
  public id: string;
  public code: string;
  public name: string;

  constructor(params: AppliedCouponModel) {
    this.id = params.id;
    this.code = params.code;
    this.name = params.name;
  }

  public static fromGQL(appliedCoupon: IAppliedCoupon) {
    return new AppliedCouponModel({
      id: appliedCoupon?.couponId,
      code: appliedCoupon?.code,
      name: appliedCoupon?.name,
    });
  }
}
