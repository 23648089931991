import {IShippingInfo} from '../../types/app.types';
import {ShippingRuleModel} from './ShippingRule.model';

export class ShippingInfoModel {
  public status: string;
  public shippingRule: ShippingRuleModel;
  public canShipToDestination: boolean;

  constructor(params: ShippingInfoModel) {
    this.status = params.status;
    this.shippingRule = params.shippingRule;
    this.canShipToDestination = params.canShipToDestination;
  }

  public static fromGQL(shippingInfo: IShippingInfo) {
    return new ShippingInfoModel({
      status: shippingInfo?.status ?? undefined,
      shippingRule: shippingInfo?.shippingRule ? ShippingRuleModel.fromGQL(shippingInfo?.shippingRule) : undefined,
      canShipToDestination: shippingInfo?.canShipToDestination ?? undefined,
    });
  }
}
