import {IFreeText} from '../../types/app.types';

export class FreeTextModel {
  public title: string;
  public value: string;

  constructor(params: FreeTextModel) {
    this.value = params.value;
    this.title = params.title;
  }

  public static fromGQL(freeText: IFreeText) {
    return new FreeTextModel({
      title: freeText?.title,
      value: freeText?.value,
    });
  }
}
