import {IMinimumOrderAmount} from '../../types/app.types';
import {PriceModel} from './Price.model';

export class MinimumOrderAmountModel {
  public reached: boolean;
  public minAmount: PriceModel;
  public additionalAmount: PriceModel;

  constructor(params: MinimumOrderAmountModel) {
    this.reached = params.reached;
    this.minAmount = params.minAmount;
    this.additionalAmount = params.additionalAmount;
  }

  public static fromGQL(minimumOrderAmount: IMinimumOrderAmount) {
    return new MinimumOrderAmountModel({
      reached: minimumOrderAmount.reached,
      minAmount: PriceModel.fromGQL({
        convertedAmount: minimumOrderAmount.convertedValue,
        formattedConvertedAmount: minimumOrderAmount.formattedConvertedValue,
      }),
      additionalAmount: PriceModel.fromGQL({
        convertedAmount: minimumOrderAmount.convertedDiff,
        formattedConvertedAmount: minimumOrderAmount.formattedConvertedDiff,
      }),
    });
  }
}
